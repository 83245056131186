<template>
   <el-col class="cont">
      <!-- 面包屑 -->
      <Breadcrumb :crumbs="crumbs" />

      <!-- 运营分析 -->
      <el-row class="reten-full">
         <el-row class="title">运营分析</el-row>
         <el-row class="row-data-box">
            <el-col :span="6" :offset="1">
               <el-date-picker
                  v-model="operateDateVals"
                  type="daterange"
                  value-format="yyyy-MM-dd hh:mm:ss"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
               </el-date-picker>
            </el-col>
            <el-col :span="4">
               <el-button type="primary" @click="operateData()">查询</el-button>
            </el-col>
         </el-row>
         <el-row id="operate" class="echa-box" style="width: 90%; height: 400px; margin-top: 1rem;" ></el-row>
      </el-row>

      <!-- 效果分析 -->
      <el-row class="reten-full">
         <el-row class="title">效果分析</el-row>
         <el-row class="row-data-box">
            <el-col :span="6" :offset="1">
               <el-date-picker
                  v-model="effectDateVals"
                  type="daterange"
                  value-format="yyyy-MM-dd hh:mm:ss"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
               </el-date-picker>
            </el-col>
            <el-col :span="4">
               <el-select v-model="channel" placeholder="选择运营渠道">
                  <el-option
                     v-for="item in platform"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                  </el-option>
               </el-select>
            </el-col>
            <el-col :span="4">
               <el-button type="primary" @click="getEffectData()">查询</el-button>
            </el-col>
         </el-row>
         <el-row class="echa-box">
            <el-table :data="effectData">
               <el-table-column prop="eventName" label="活动名称"></el-table-column>
               <el-table-column prop="createTime" label="活动时间"></el-table-column>
               <el-table-column prop="joinUserCount" label="预计触达人数"></el-table-column>
               <el-table-column prop="reachUserCount" label="实际触达人数"></el-table-column>
               <el-table-column label="触达率">
                  <template slot-scope="scope">
                     {{scope.row.touchOfRate * 100 + '%'}}
                  </template>
               </el-table-column>
            </el-table>
         </el-row>
      </el-row>
   </el-col>

</template>

<script>
   import { mapState } from 'vuex'
   import {dateFactory} from "@/common/js/common";
   import { getCountMarket, effectAnalysis } from '@/api/smtech/operateAnalysis'
  
   export default {
      computed: {
         ...mapState(['hotelInfo'])
      },
      data(){
         return {
            crumbs: new Map([['营销云'], ['分析'], ['运营分析']]),
            operateDateVals: null, // 运营分析时间
            platform: [
               {
                  label: '公众号',
                  value: 'PUBLICACCOUNT'
               },
               {
                  label: '短信',
                  value: 'MESSAGE'
               },
               {
                  label: '小程序',
                  value: 'WECHAT'
               },
            ],
            channel: 'PUBLICACCOUNT', // 运营渠道
            effectDateVals: null, // 效果分析时间
            effectData: [], // 效果分析数据
            // 运营分析数据
            operate : {
               color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FFBF00'],
               tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                     type: 'cross',
                     label: {
                        backgroundColor: '#6a7985'
                     }
                  }
               },
               legend: {
                  data: ['公众号群发活动', '微信群发活动', '小程序弹窗', '短信']
               },
               grid: {
                  left: '6%',
                  right: '10%',
                  bottom: '3%',
                  containLabel: true
               },
               xAxis: [
                  {
                     type: 'category',
                     boundaryGap: false,
                     data: []
                  }
               ],
               yAxis: [
                  {
                     type: 'value'
                  }
               ],
               series: [
                  {
                     name: '公众号群发活动',
                     type: 'line',
                     stack: '次数',
                     smooth: true,
                     lineStyle: {
                        width: 0
                     },
                     showSymbol: false,
                     areaStyle: {
                        opacity: 0.8,
                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                           offset: 0,
                           color: 'rgba(128, 255, 165)'
                        }, {
                           offset: 1,
                           color: 'rgba(1, 191, 236)'
                        }])
                     },
                     emphasis: {
                        focus: 'series'
                     },
                     data: []
                  },
                  {
                     name: '微信群发活动',
                     type: 'line',
                     stack: '次数',
                     smooth: true,
                     lineStyle: {
                        width: 0
                     },
                     showSymbol: false,
                     areaStyle: {
                        opacity: 0.8,
                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                           offset: 0,
                           color: 'rgba(0, 221, 255)'
                        }, {
                           offset: 1,
                           color: 'rgba(77, 119, 255)'
                        }])
                     },
                     emphasis: {
                        focus: 'series'
                     },
                     data: []
                  },
                  {
                     name: '小程序弹窗',
                     type: 'line',
                     stack: '次数',
                     smooth: true,
                     lineStyle: {
                        width: 0
                     },
                     showSymbol: false,
                     areaStyle: {
                        opacity: 0.8,
                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                           offset: 0,
                           color: 'rgba(55, 162, 255)'
                        }, {
                           offset: 1,
                           color: 'rgba(116, 21, 219)'
                        }])
                     },
                     emphasis: {
                        focus: 'series'
                     },
                     data: []
                  },
                  {
                     name: '短信',
                     type: 'line',
                     stack: '次数',
                     smooth: true,
                     lineStyle: {
                        width: 0
                     },
                     showSymbol: false,
                     areaStyle: {
                        opacity: 0.8,
                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                           offset: 0,
                           color: 'rgba(255, 0, 135)'
                        }, {
                           offset: 1,
                           color: 'rgba(135, 0, 157)'
                        }])
                     },
                     emphasis: {
                        focus: 'series'
                     },
                     data: []
                  },
               ]
            },
         }
      },
     mounted() {
      this.operateDateVals = this.effectDateVals = [dateFactory.getDistanceToday(0), dateFactory.getDistanceToday(6)]
      let operate = this.$echarts.init(document.getElementById('operate'))
      
      window.onresize=function(){
         operate.resize()
      }
      this.operateData()
      this.getEffectData()
     },
      methods:{
         // 运营分析
         operateData(){
            if (!this.operateDateVals) {
               this.$message.error('请选择时间')
               return
            }
            let param = {
               beginTime: this.operateDateVals[0],
               endTime: this.operateDateVals[1],
               hotelId: this.hotelInfo.id
            }
            getCountMarket(param).then(res => {
               if (res.records.length != 0) {
                  let date = [], publicAccountCount = [], massCount = [], messageCount = [], appletCount = []
                  for (let i in res.records) {
                     date.push(res.records[i].date)
                     publicAccountCount.push(res.records[i].publicAccountCount)
                     massCount.push(res.records[i].massCount)
                     messageCount.push(res.records[i].messageCount)
                     appletCount.push(res.records[i].appletCount)
                  }
                  this.operate.xAxis[0].data = date
                  this.operate.series[0].data = publicAccountCount
                  this.operate.series[1].data = massCount
                  this.operate.series[2].data = appletCount
                  this.operate.series[3].data = messageCount
                  this.$echarts.init(document.getElementById('operate')).setOption(this.operate);
               } else {
                   this.$message.error('当前所选时间没有数据')
               }
            })
         },
         // 获取效果分析数据
         getEffectData() {
             if (!this.effectDateVals) {
               this.$message.error('请选择时间')
               return
            }
            if (this.channel == '') {
                this.$message.error('请选择渠道')
                return
            }
            let param = {
               beginTime: this.effectDateVals[0],
               endTime: this.effectDateVals[1],
               platform: this.channel,
               hotelId: this.hotelInfo.id
            }
            effectAnalysis(param).then(res => {
               this.effectData = res.records
            })
         }
      }
   }
</script>

<style scoped lang="scss">

.reten-full{
   margin: 20px;
   background-color: white;
   box-shadow: 0px 0px 20px #dce7f9;
   margin-bottom: 30px;
   .title{
      height: 50px;
      line-height: 50px;
      background: #f7f7f8;
      padding: 0px 20px;
   }
   .row-data-box{
      height: 50px;
      line-height: 50px;
      padding: 0px 20px;
      margin-top: 20px;
   }
   .echa-box{
      padding: 30px 20px;
   }
}

</style>