import request from "@/api/service";

const api = {
   getCountMarket: '/event/eventMessage/getCountMarket', // 运营分析
   effectAnalysis: '/event/eventMessage/effectAnalysis', // 效果分析
}

export function effectAnalysis(params) {
   return request({
      url: api.effectAnalysis,
      method: 'POST',
      data: params
   })
}

export function getCountMarket(params) {
   return request({
      url: api.getCountMarket,
      method: 'POST',
      data: params
   })
}






